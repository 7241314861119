import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/contact.module.scss";
import { Link, useIntl } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { contacto, contactoSet } from "../redux/contacto/actions";
import { Icon } from "semantic-ui-react";

const ContactoPage = ({ contactoData, contacto, contactoSet }) => {
	const intl = useIntl();

	const go = (e) => {
		e.preventDefault();
		contacto(intl.locale, {
			...contactoData,
			messages: {
				success: {
					title: "¡Gracias!",
					body: "Hemos recibido tu mensaje y nos comunicaremos contigo a la brevedad.",
					button: "Aceptar",
				},
				error: {
					title: "¡Atención!",
					body: "Debes completar todos los campos del formulario antes de enviar.",
					button: "Aceptar",
				},
			},
		});
	};

	return (
		<Layout>
			<Seo title="Contacto" />
			<section className={styles.contact}>
				<article className={styles.bgContact}>
					<div className={styles.contactWrapper}>
						<Link to="/" className="lineLink">
							<u>Volver a la home</u>
						</Link>
					</div>
					<div className={styles.titleWrapper}>
						<h2>Contacto</h2>
					</div>
				</article>

				<article className={styles.contactForm}>
					<p>
						¿Tienes alguna duda o sugerencia o quieres ponerte en contacto
						con nosotros? Rellena el formulario y te contestaremos lo
						antes posible.
					</p>
					<form onSubmit={go}>
						<input
							type="text"
							className={styles.name}
							placeholder="Nombre"
							value={contactoData.nombre}
							onChange={(e) => contactoSet({ nombre: e.target.value })}
						/>
						<input
							type="email"
							className={styles.email}
							placeholder="Email"
							value={contactoData.email}
							onChange={(e) => contactoSet({ email: e.target.value })}
						/>
						<textarea
							className={styles.message}
							placeholder="Mensaje"
							value={contactoData.mensaje}
							onChange={(e) => contactoSet({ mensaje: e.target.value })}
						/>
						<button
							type="submit"
							className={styles.button}
							disabled={contactoData.loading}
						>
							Enviar
							{contactoData.loading && (
								<>
									&nbsp;&nbsp;
									<Icon loading name="circle notch" />
								</>
							)}
						</button>
					</form>
				</article>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		contactoData: state.contacto,
	};
};

const mapDispatchToProps = {
	contacto,
	contactoSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactoPage);
