import actionTypes from "../actionTypes";

export const contactoSet = (data) => {
	return {
		type: actionTypes.CONTACTO_SET,
		payload: data,
	};
};

export const contacto = (lang, data) => {
	return {
		type: actionTypes.CONTACTO_INIT,
		payload: { lang, data },
	};
};
